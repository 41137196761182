import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Button from 'components/atoms/Button';
import { Pagination } from 'components/molecules/Pagination';
import { ERouteLinks } from 'models/route';
import { Table } from 'components/molecules/Table';
import { useTranslations } from 'hooks/useTranslations';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { useNavigate, useParams } from 'react-router-dom';
import { CardList } from 'components/molecules/CardList';
import { Loader } from 'components/atoms/Loader';
import { Box } from '@mui/system';
import { EPerPages, ISortOptions } from 'models/table';
import { EButtonVariants } from 'constants/Buttons';
import { EIconTypes } from 'constants/Icons';
import { useAncillaries } from 'pages/AncillariesPage/hooks';
import { IAccessoriesTransactionsFormValues } from 'models/ancillaries';
import { useGlobalProperty } from 'models/globalPropertyContext';
import { useAlert } from 'models/alertContext';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants, IAlert } from 'components/atoms/Alert';
import { EPermission } from 'models/permissions';
import { EAncillaryState } from 'constants/ArtAccessoryType';
import { usePermission } from 'hooks/usePermission';
import { ELegalVisibility } from 'constants/LegalVisibility';
import { useAccount } from 'pages/AccountPage/hooks';
import { useAuth } from 'models/authContext';
import { useLang } from 'models/langContext';
import { AccessoriesTransactionsSearchForm } from '../AccessoriesTransactionsSearchForm';
import {
  AccessoriesTranslationsTableColumns,
  mapAccessoriesTranslationsToDataSource,
} from '../helpers';
import { AccessoriesTransactionsCard } from '../AccessoriesTransactionsCard';

interface IAccessoriesTransactions {
  inGroup: boolean;
  stateUniqueId: string | undefined;
  ownerVisibilityUniqueId: string | undefined;
  ownerId: string | undefined;
  keeperVisibilityUniqueId: string | undefined;
  keeperId: string | undefined;
}

const AccessoriesTransactions = ({
  inGroup,
  stateUniqueId,
  keeperVisibilityUniqueId,
  ownerVisibilityUniqueId,
  keeperId,
  ownerId,
}: IAccessoriesTransactions) => {
  const { isDesktop } = useThemeBreakpoint();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslations();
  const { midDateFormat } = useGlobalProperty();
  const { clearAlert, setAlert, samePageAlert } = useAlert();
  const { hasPermissions } = usePermission();
  const {
    getAccessoriesTransactionsData,
    paginatorAccessoriesTransactions,
    accessoriesTransactions,
    discardableTransactionId,
    getDiscardableTransactionId,
  } = useAncillaries();

  const [currentPageAccessoriesTransactions, setCurrentPageAccessoriesTransactions] =
    useState<number>(1);
  const [perPageAccessoriesTransactions, setPerPageAccessoriesTransactions] =
    useState<EPerPages>(EPerPages.perPage25);
  const [isTransactionsLoading, setIsTransactionsLoading] = useState<boolean | null>(null);
  const [transactionsQuery, setTransactionsQuery] = useState<
    Partial<IAccessoriesTransactionsFormValues>
  >({});
  const [transactionSort, setTransactionSort] = useState<ISortOptions | null>(null);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);

  const { user, getUser } = useAuth();
  const { selectedLanguage } = useLang();
  const { account, getSelfAccountData } = useAccount();

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    (async function getAccountDataInit() {
      if (user?.userId) {
        await getSelfAccountData();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    (async function getDataInit() {
      if (id) {
        setIsPaginationLoading(true);
        if (!accessoriesTransactions.length) {
          setIsTransactionsLoading(true);
        }
        await getAccessoriesTransactionsData(
          id,
          currentPageAccessoriesTransactions,
          perPageAccessoriesTransactions,
          {
            ...transactionsQuery,
          },
          transactionSort,
        );
        getDiscardableTransactionId(id);
        setIsTransactionsLoading(false);
        setIsPaginationLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    id,
    currentPageAccessoriesTransactions,
    perPageAccessoriesTransactions,
    transactionsQuery,
    transactionSort,
    selectedLanguage,
  ]);

  useEffect(
    () => () => {
      setIsTransactionsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const isAddTransactionVisible = () => {
    if (
      [EAncillaryState.DISABLED, EAncillaryState.DESTROYED].includes(
        stateUniqueId as EAncillaryState,
      )
    )
      return false;
    if (hasPermissions(EPermission.VIEW_ALL_LE)) return true;
    if (hasPermissions(EPermission.VIEW_LE)) {
      return (
        (keeperVisibilityUniqueId !== ELegalVisibility.NON_PUBLIC ||
          account?.assignedLegalIds.includes(keeperId!)) &&
        (ownerVisibilityUniqueId !== ELegalVisibility.NON_PUBLIC ||
          account?.assignedLegalIds.includes(ownerId!))
      );
    }
    return false;
  };

  const handleAddTransaction = () => {
    if (inGroup) {
      clearAlert(ActionTypes.SET_SAME_PAGE_ALERT);
      clearAlert(ActionTypes.SET_TRANSACTION_ALERT);
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('ancillary.transaction.addTransaction.text'),
        variant: EAlertVariants.warning,
      } as IAlert);
    } else {
      navigate(ERouteLinks.AncillariesMiddleTransaction.replace(':id', id as string));
    }
  };

  return (
    <div>
      <AccessoriesTransactionsSearchForm onSubmit={setTransactionsQuery} />
      <Grid
        item
        xs={12}
        container
        sx={{ display: 'flex', justifyContent: 'flex-end', margin: '24px 0' }}
      >
        {isAddTransactionVisible() && (
          <Button
            variant={EButtonVariants.contained}
            icon={EIconTypes.plus}
            label={t('accessoryDetails.transaction.addTransaction.button')}
            onClick={handleAddTransaction}
            permission={[EPermission.ADD_TRANSACTION]}
          />
        )}
      </Grid>
      {samePageAlert && (
        <Box mt={2} sx={{ marginBottom: '10px' }}>
          <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
        </Box>
      )}
      {isTransactionsLoading ? (
        <Loader isLoading={isTransactionsLoading} />
      ) : (
        <>
          {isDesktop ? (
            <Table
              columns={AccessoriesTranslationsTableColumns({
                handleDelete:
                  hasPermissions([
                    EPermission.VIEW_ALL_TRANSACTIONS,
                    EPermission.DISCARD_TRANSACTION,
                  ]) ||
                  hasPermissions([
                    EPermission.VIEW_TRANSACTIONS,
                    EPermission.DISCARD_TRANSACTION,
                  ])
                    ? (transactionId: string) =>
                        navigate(ERouteLinks.CancelTransaction.replace(':id', transactionId))
                    : null,
                discardableTransactionId,
                dateFormat: midDateFormat,
              })}
              onSort={setTransactionSort}
              dataSource={mapAccessoriesTranslationsToDataSource(accessoriesTransactions)}
              translationsKeys={{
                noResults: 'ancillaries.accordion.noResults.text',
              }}
            />
          ) : (
            <CardList
              items={mapAccessoriesTranslationsToDataSource(accessoriesTransactions)}
              render={(data, index) => (
                <AccessoriesTransactionsCard
                  data={data}
                  key={index}
                  discardableTransactionId={discardableTransactionId}
                  handleView={(idTransactions: string) =>
                    navigate(ERouteLinks.ViewTransaction.replace(':id', idTransactions))
                  }
                />
              )}
            />
          )}

          {paginatorAccessoriesTransactions && (
            <Pagination
              count={paginatorAccessoriesTransactions?.totalElements}
              perPage={perPageAccessoriesTransactions}
              onChangePage={(page) => {
                setCurrentPageAccessoriesTransactions(page);
              }}
              onChangePerPage={(value) => {
                setPerPageAccessoriesTransactions(value);
              }}
              current={currentPageAccessoriesTransactions}
              isLoading={isPaginationLoading}
            />
          )}
        </>
      )}
    </div>
  );
};

export { AccessoriesTransactions };

import React, { useEffect, useState } from 'react';
import { SearchWrapper } from 'components/atoms/SearchWrapper';
import Grid from '@mui/material/Grid';
import { FormInput } from 'components/molecules/FormInput';
import { useForm } from 'react-hook-form';
import { FormSelect } from 'components/molecules/FormSelect';
import {
  mapDictionaryNamesToOptionProp,
  mapDictionaryToOptionProp,
  mapSpecificDictionaryToOptionProp,
} from 'helpers/dictionary';
import Button from 'components/atoms/Button';
import { EButtonSizes, EButtonVariants } from 'constants/Buttons';
import { SelectedFilters } from 'components/organisms/SelectedFilters';
import {
  initialGroupAncillarySearchFormValues,
  ISearchAncillaryFormValues,
} from 'models/form';
import { useTranslations } from 'hooks/useTranslations';
import { EDictionaryTypes, IDictionary } from 'models/dictionary';
import { prepareFilters } from 'helpers/filters';
import { useLang } from 'models/langContext';
import { ESearchGroupAncillaryFormLabelsTypes } from 'constants/SearchFormLabelsTypes';
import { EArtAccessoryType, EArtAncillaryType } from 'constants/ArtAccessoryType';
import { FormAutocomplete } from 'components/molecules/FormAutocomplete';
import {
  getAncillaryNameById,
  mapArtAncillaryDictionaryToOptionProp,
} from 'helpers/ancillary';
import { useLegalEntities } from '../hooks';

interface IGroupAncillarySearchForm {
  onSubmit: (arg: ISearchAncillaryFormValues) => void;
}
const GroupAncillarySearchForm = ({ onSubmit }: IGroupAncillarySearchForm) => {
  const { t } = useTranslations();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<ISearchAncillaryFormValues>({
    defaultValues: initialGroupAncillarySearchFormValues,
  });
  const [values, setValues] = useState<ISearchAncillaryFormValues>(
    initialGroupAncillarySearchFormValues,
  );
  const [ancillaryTypeOptions, setAncillaryTypeOptions] = useState<IDictionary[] | null>(null);
  const [manufacturerOptions, setManufacturerOptions] = useState<IDictionary[] | null>(null);

  const [manufacturerIsDisabled, setManufacturerIsDisabled] = useState<boolean>(true);

  const { selectedLanguage } = useLang();
  const {
    firearmType,
    artAncillaryOptions,
    artComponentType,
    artAccessoryType,
    artAmmunitionType,
    artAccessoryManufacturerFlashEliminator,
    artAccessoryManufacturerLaserLightModule,
    artAccessoryManufacturerMagazine,
    artAccessoryManufacturerOpticalSight,
    artAccessoryManufacturerSuppressor,
    artAmmunitionManufacturer,
    artComponentManufacturer,
    loadDictionaries,
  } = useLegalEntities();

  useEffect(() => {
    (async function initDictionaries() {
      await loadDictionaries([
        EDictionaryTypes.FirearmType,
        EDictionaryTypes.ArtAncillaryOptions,
        EDictionaryTypes.ArtAccessoryType,
        EDictionaryTypes.ArtAmmunitionType,
        EDictionaryTypes.ArtComponentType,
        EDictionaryTypes.ArtAccessoryManufacturerFlashEliminator,
        EDictionaryTypes.ArtAccessoryManufacturerLaserLightModule,
        EDictionaryTypes.ArtAccessoryManufacturerMagazine,
        EDictionaryTypes.ArtAccessoryManufacturerOpticalSight,
        EDictionaryTypes.ArtAccessoryManufacturerSuppressor,
        EDictionaryTypes.ArtAmmunitionManufacturer,
        EDictionaryTypes.ArtComponentManufacturer,
      ]);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLanguage]);

  useEffect(() => {
    const subscription = watch((value) => {
      setValues(value as ISearchAncillaryFormValues);
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const CheckManufacturerIsDisabled = (
    ancillarySelectedData: string | null,
    ancillarySpecificTypeSelectedData: string | null,
  ) => {
    const ancillarySelected = ancillarySelectedData || (getValues('ancillaryType') as string);
    if (ancillarySelected && ancillarySelected === EArtAncillaryType.ACCESSORIES) {
      const ancillarySpecificTypeSelected =
        ancillarySpecificTypeSelectedData ||
        (getValues('ancillarySpecificTypeUniqueId') as string);
      if (ancillarySpecificTypeSelected) {
        setManufacturerIsDisabled(false);
      } else {
        setManufacturerIsDisabled(true);
      }
    } else if (ancillarySelected && ancillarySelected === EArtAncillaryType.COMPONENTS) {
      setManufacturerIsDisabled(false);
    } else if (ancillarySelected && ancillarySelected === EArtAncillaryType.AMMUNITION) {
      setManufacturerIsDisabled(false);
    } else {
      setManufacturerIsDisabled(true);
    }
  };
  const handleResetField = (name?: string, refreshResults: boolean = true) => {
    if (name) {
      reset({ ...getValues(), [name]: '' });
    } else {
      reset(initialGroupAncillarySearchFormValues);
    }

    if (refreshResults) {
      onSubmit(getValues());
    }
    CheckManufacturerIsDisabled(null, null);
    if (!getValues('ancillaryType')) {
      setAncillaryTypeOptions(null);
    }
  };

  const onSubmitForm = async (formData: any) => {
    const data = { ...formData };
    if (data.ancillaryType) {
      data.ancillaryType = getAncillaryNameById(data.ancillaryType);
    }
    await onSubmit(data);
  };

  const handleSetAncillaryOption = (type: string) => {
    if (type) {
      if (type === EArtAncillaryType.COMPONENTS) {
        setAncillaryTypeOptions(artComponentType);
        setManufacturerOptions(artComponentManufacturer);
      }
      if (type === EArtAncillaryType.ACCESSORIES) {
        setAncillaryTypeOptions(artAccessoryType);
      }
      if (type === EArtAncillaryType.AMMUNITION) {
        setAncillaryTypeOptions(artAmmunitionType);
        setManufacturerOptions(artAmmunitionManufacturer);
      }
    } else {
      setAncillaryTypeOptions(null);
    }
    setValue('ancillarySpecificTypeUniqueId', '');
    setValue('manufacturerUniqueId', '');
    CheckManufacturerIsDisabled(type, null);
  };

  const handleSetAncillaryTypeOption = (type: string) => {
    const ancillarySelected = getValues('ancillaryType') as string;
    if (ancillarySelected && ancillarySelected === EArtAncillaryType.ACCESSORIES) {
      setValue('manufacturerUniqueId', '');
      switch (type) {
        case EArtAccessoryType.MAGAZINES:
          setManufacturerOptions(artAccessoryManufacturerMagazine);
          break;
        case EArtAccessoryType.LASER_LIGHT_MODULES:
          setManufacturerOptions(artAccessoryManufacturerLaserLightModule);
          break;
        case EArtAccessoryType.OPTICAL_SIGHTS:
          setManufacturerOptions(artAccessoryManufacturerOpticalSight);
          break;
        case EArtAccessoryType.FLASH_ELIMINATORS:
          setManufacturerOptions(artAccessoryManufacturerFlashEliminator);
          break;
        case EArtAccessoryType.SUPPRESSORS:
          setManufacturerOptions(artAccessoryManufacturerSuppressor);
          break;
        default:
          setManufacturerOptions(null);
          break;
      }
    } else if (
      !(
        ancillarySelected &&
        (ancillarySelected === EArtAncillaryType.COMPONENTS ||
          ancillarySelected === EArtAncillaryType.AMMUNITION)
      )
    ) {
      setManufacturerOptions(null);
    }

    CheckManufacturerIsDisabled(ancillarySelected, type);
  };

  return (
    <SearchWrapper>
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <Grid container justifyContent="space-between" spacing={2}>
          <Grid item lg={3} md={6} xs={12}>
            <FormSelect
              options={mapArtAncillaryDictionaryToOptionProp(artAncillaryOptions, t)}
              name="ancillaryType"
              canBeEmpty={true}
              label={t('ancillaries.searchForAncillary.ancillary.filter.label')}
              control={control}
              setValue={(name: string, value: string) => {
                setValue('ancillaryType', value);
                if (!value) {
                  handleSetAncillaryOption(value);
                }
              }}
              onSelect={(value) => {
                handleSetAncillaryOption(value);
              }}
              errors={errors}
            />
          </Grid>
          <Grid item lg={3} md={6} xs={12}>
            <FormSelect
              options={mapDictionaryToOptionProp(ancillaryTypeOptions)}
              name="ancillarySpecificTypeUniqueId"
              canBeEmpty={true}
              label={t('ancillaries.searchForAncillary.ancillaryType.filter.label')}
              control={control}
              setValue={(name: string, value: string) => {
                setValue('ancillarySpecificTypeUniqueId', value);
                if (!value) {
                  handleSetAncillaryTypeOption(value);
                }
              }}
              errors={errors}
              disabled={!ancillaryTypeOptions}
              onSelect={(value) => {
                handleSetAncillaryTypeOption(value);
              }}
            />
          </Grid>
          <Grid item lg={3} md={6} xs={12}>
            <FormSelect
              options={mapSpecificDictionaryToOptionProp(firearmType)}
              name="firearmType"
              canBeEmpty={true}
              label={t('ancillaries.searchForAncillary.firearmType.filter.label')}
              control={control}
              setValue={setValue}
              errors={errors}
              disabled={getValues('ancillaryType') !== EArtAncillaryType.COMPONENTS}
            />
          </Grid>
          <Grid item lg={3} md={6} xs={12}>
            <FormInput
              name="serialNumber"
              label={t('ancillaries.searchForAncillary.serialNumber.filter.label')}
              setValue={setValue}
              control={control}
              errors={errors}
              disabled={
                getValues('ancillaryType') !== EArtAncillaryType.COMPONENTS &&
                getValues('ancillaryType') !== EArtAncillaryType.ACCESSORIES
              }
            />
          </Grid>
          <Grid item lg={3} md={6} xs={12}>
            <FormInput
              name="lotNumber"
              label={t('ancillaries.searchForAncillary.lotNumber.filter.label')}
              setValue={setValue}
              control={control}
              errors={errors}
              disabled={getValues('ancillaryType') !== EArtAncillaryType.AMMUNITION}
            />
          </Grid>
          <Grid item lg={3} md={6} xs={12}>
            <FormInput
              name="batchNumber"
              label={t('ancillaries.searchForAncillary.batchNumber.filter.label')}
              setValue={setValue}
              control={control}
              errors={errors}
              disabled={getValues('ancillaryType') !== EArtAncillaryType.AMMUNITION}
            />
          </Grid>
          <Grid item lg={3} md={6} xs={12}>
            <FormAutocomplete
              options={
                manufacturerOptions
                  ? mapDictionaryNamesToOptionProp(manufacturerOptions, true)
                  : []
              }
              name="manufacturerUniqueId"
              label={t('ancillaries.searchForAncillary.manufacturer.filter.label')}
              control={control}
              errors={errors}
              disabled={manufacturerIsDisabled}
            />
          </Grid>
          <Grid item lg={3} md={6} xs={12}>
            <Button
              variant={EButtonVariants.contained}
              size={EButtonSizes.small}
              label={t('ancillaries.searchForAncillary.search.label')}
              type="submit"
              fullWidth={true}
              sx={{ mt: 3.5 }}
            />
          </Grid>
        </Grid>
      </form>
      <SelectedFilters
        name="group_ancillary"
        values={values}
        labels={ESearchGroupAncillaryFormLabelsTypes}
        dictionaries={{
          manufacturerUniqueId: prepareFilters(manufacturerOptions, 'uniqueId', 'name'),
          firearmType: prepareFilters(firearmType, 'uniqueId', 'name'),
          ancillaryType: prepareFilters(artAncillaryOptions, 'uniqueId', 'name'),
          ancillarySpecificTypeUniqueId: prepareFilters(
            ancillaryTypeOptions,
            'uniqueId',
            'name',
          ),
        }}
        handleDelete={handleResetField}
        noPreset
        showSaveFilters={false}
        saveRouterStateAfterClearAll={false}
        saveQueryParams={['type']}
      />
    </SearchWrapper>
  );
};

export { GroupAncillarySearchForm };

import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { StyledPageTitle } from 'theme/styles';
import { DictionaryValueForm } from 'pages/DictionariesPage/ViewDictionaryPage/DictionaryValueForm';
import TableFormWrapper from 'components/atoms/FormWrapper';
import { useDictionaries } from 'pages/DictionariesPage/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { ERouteLinks } from 'models/route';
import { ActionTypes } from 'state/actions/alert';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { Box } from '@mui/material';
import {
  EDictionaryTypes,
  EManagedByAdminAndNoTranslationsDictionaries,
  IDictionaryValueFormValues,
} from 'models/dictionary';
import { useTranslations } from 'hooks/useTranslations';
import { initialDictionaryValueFormValues } from 'models/form';

const AddDictionaryValuePage = () => {
  const navigate = useNavigate();
  const { type } = useParams<{ type: EDictionaryTypes }>();
  const { setAlert, clearAlert, samePageAlert } = useAlert();
  const { getDictionaryTypeLanguagesData, dictionaryLanguages, handleCreate } =
    useDictionaries();
  const [initialValues, setInitialValues] = useState<IDictionaryValueFormValues>(
    initialDictionaryValueFormValues,
  );
  const { t } = useTranslations();
  const hasOnlyEnglishTranslations = Object.values(
    EManagedByAdminAndNoTranslationsDictionaries,
  ).includes(type?.toString() as EManagedByAdminAndNoTranslationsDictionaries);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(() => {
    if (type) {
      (async function getDictionariesDataInit() {
        await getDictionaryTypeLanguagesData(type);
      })();
    }
  }, [type]);

  useEffect(() => {
    const languagesData: any = [];
    dictionaryLanguages.forEach((lang) => {
      languagesData[`${lang.uniqueId}`] = '';
    });
    setInitialValues({
      ...initialDictionaryValueFormValues,
      translations: languagesData,
    });
  }, [type, dictionaryLanguages]);

  const handleSubmit = async (data: IDictionaryValueFormValues) => {
    if (data && type) {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
      try {
        await handleCreate(data, type);
        setAlert(ActionTypes.SET_NEXT_PAGE_ALERT, {
          text: t('addDictionaryValue.success.text'),
          variant: EAlertVariants.success,
        });
        navigate(ERouteLinks.ViewDictionary.replace(':type', type));
      } catch (e: any) {
        if (e.response.data.message) {
          setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
            text: e.response.data.message,
            variant: EAlertVariants.error,
          });
        }
        setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
          text: e.response.data.errors[0].message,
          variant: EAlertVariants.error,
        });
      }
    }
  };

  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.administration.name'), route: ERouteLinks.Administration },
          {
            label: t('dictionary.header'),
            route: ERouteLinks.Dictionaries,
          },
          {
            label: `${t('dictionary.header')} ${t(
              'dictionaries.dictionariesTable.view.link',
            )}`,
            route: ERouteLinks.ViewDictionary.replace(':type', type as string),
          },
          t('addDictionaryValue.header'),
        ]}
      />
      <StyledPageTitle variant="h4">{t('addDictionaryValue.header')}</StyledPageTitle>

      <TableFormWrapper>
        <DictionaryValueForm
          onCancel={() =>
            navigate(ERouteLinks.ViewDictionary.replace(':type', type as string))
          }
          type={type}
          managedByAdmin={true}
          translatedValues={true}
          onSubmit={handleSubmit}
          initialFormValues={initialValues}
          dictionaryLanguages={dictionaryLanguages}
          hasOnlyEnglishTranslations={hasOnlyEnglishTranslations}
        />
      </TableFormWrapper>

      {samePageAlert && (
        <Box mt={2}>
          <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
        </Box>
      )}
    </>
  );
};

export { AddDictionaryValuePage };

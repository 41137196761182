import { EArtAncillaryType, EArtAncillaryTypeName } from 'constants/ArtAccessoryType';
import { IDictionary } from 'models/dictionary';
import { IFormElementOptionProp } from 'models/form';

const getAncillaryNameById = (uniqueId: string) => {
  switch (uniqueId) {
    case EArtAncillaryType.ACCESSORIES:
      return EArtAncillaryTypeName.ACCESSORY.toUpperCase();
    case EArtAncillaryType.AMMUNITION:
      return EArtAncillaryTypeName.AMMUNITION.toUpperCase();
    case EArtAncillaryType.COMPONENTS:
      return EArtAncillaryTypeName.COMPONENTS.toUpperCase();
    default:
      return null;
  }
};

const handleArtAncillaryNames = (name: string, t: (key: string) => string) => {
  const translationMap: Record<string, string> = {
    'Controlled Accessories': 'ancillaries.accessories.accordion.name',
    Ammunition: 'ancillaries.ammunition.accordion.name',
    'Essential Components': 'ancillaries.components.accordion.name',
  };
  return translationMap[name] ? t(translationMap[name]) : name;
};

export const mapArtAncillaryDictionaryToOptionProp = (
  dictionary: IDictionary[] | null,
  t: (key: string) => string,
): IFormElementOptionProp[] => {
  if (!dictionary) {
    return [];
  }
  return dictionary
    .filter((item) => item.isActive)
    .map((item) => ({
      label: handleArtAncillaryNames(item.name, t),
      value: item.uniqueId,
    }));
};

export { getAncillaryNameById };

import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Logo from 'components/atoms/Logo';
import LogoImage from 'assets/images/Logo.svg';
import { Box } from '@mui/system';
import { Alert, EAlertVariants } from 'components/atoms/Alert';
import { Typography } from '@mui/material';
import { FormInput } from 'components/molecules/FormInput';
import Button from 'components/atoms/Button';
import { EButtonSizesPX, EButtonVariants } from 'constants/Buttons';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useStartLicenceFormSchema } from 'pages/StartLicencePage/schemaValidation';
import { ActionTypes } from 'state/actions/alert';
import { useAlert } from 'models/alertContext';
import { colors } from 'theme/colors';
import UploadDropzoneField from 'components/atoms/UploadDropzoneField';
import { EIconTypes } from 'constants/Icons';
import FormErrorLabel from 'components/atoms/FormErrorLabel';
import { useLicence } from 'pages/LicenceDetailsPage/hooks';
import { ERouteLinks } from 'models/route';
import { useNavigate } from 'react-router-dom';
import { useLicenceContext } from 'models/licenceContext';
import { StartLicenceTemplate } from 'components/templates/StartLicenceTemplate';
import { StyledHeading, StyledTypography } from './styles';

interface IStartLicenceFormValues {
  uploadedFile?: string;
  licenceData: string;
}

const initialFormValues: IStartLicenceFormValues = {
  uploadedFile: '',
  licenceData: '',
};

const StartLicencePage = () => {
  const { schema } = useStartLicenceFormSchema();
  const [clearFiles, setClearFiles] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
  const { clearAlert, setAlert, samePageAlert } = useAlert();
  const { replaceLicenceHandler } = useLicence();
  const navigate = useNavigate();
  const { isValidLicence } = useLicenceContext();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ defaultValues: initialFormValues, resolver: yupResolver(schema) });

  useEffect(() => {
    localStorage.clear();
  }, []);

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onSubmitForm = async (data: IStartLicenceFormValues) => {
    setIsSubmitLoading(true);

    try {
      await replaceLicenceHandler(data.licenceData);
      setAlert(ActionTypes.SET_AUTH_ALERT, {
        text: 'Licence activated. Log in to the system using the admin account.',
        variant: EAlertVariants.success,
      });
      navigate(ERouteLinks.Dashboard);
    } catch (e: any) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text:
          e.response?.data?.message ||
          'There was a problem uploading the licence file, please try again.',
        variant: EAlertVariants.error,
      });
    }

    setIsSubmitLoading(false);
  };

  const clearAttachment = () => {
    setClearFiles(true);
    setValue('uploadedFile', '');
    setValue('licenceData', '');
  };

  const readFile = (files: any[]) => {
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      setValue('licenceData', event?.target?.result as string);
    });
    reader.readAsText(files[0]);
  };

  useEffect(() => {
    if (isValidLicence()) {
      navigate(ERouteLinks.Dashboard);
    }
  });

  return (
    <StartLicenceTemplate>
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        sm={9}
        md={6}
        lg={5}
        xl={6}
        px={2}
        pb={5}
      >
        <Grid item xl={6} container justifyContent="center">
          <Grid
            item
            container
            justifyContent="center"
            sx={{ width: { xs: '120px', sm: '200px', xl: '220px' } }}
          >
            <Logo src={LogoImage} alt="Logo" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" textAlign="center" mb={2} mt={3}>
              Please enter the Licence Key
              <br />
              to activate and run the FireCycle.
            </Typography>
          </Grid>

          <form onSubmit={handleSubmit(onSubmitForm)} style={{ width: '100%' }}>
            <Grid container item rowSpacing={0} columnSpacing={2} lg={12}>
              <StyledHeading style={{ padding: '4px 15px' }}>
                <Typography variant="subtitle2" color={colors.gray900}>
                  Licence Key
                </Typography>
              </StyledHeading>

              <Grid item container rowSpacing={2} lg={12}>
                <Grid item container rowSpacing={0} columnSpacing={2} xs={12}>
                  <Grid item xs={12}>
                    <UploadDropzoneField
                      onUpload={(files: any) => {
                        readFile(files);
                        setValue('uploadedFile', files[0].name);
                      }}
                      shouldClearFiles={clearFiles}
                      setFilesCleared={setClearFiles}
                      description={
                        <p>
                          Drag & drop TXT file here <br />
                          or click to browse
                        </p>
                      }
                    />
                  </Grid>
                </Grid>

                <Grid item container xs={12} sx={{ alignItems: 'center' }}>
                  <Grid item xs="auto" sx={{ width: 'calc(100% - 48px) !important' }}>
                    <StyledHeading>
                      <Typography variant="subtitle2" color={colors.gray900}>
                        Licence Key
                      </Typography>
                    </StyledHeading>
                    <FormInput
                      name="uploadedFile"
                      label=""
                      control={control}
                      errors={errors}
                      disabled
                    />
                  </Grid>
                  <Grid item xs="auto" sx={{ marginTop: '0px', marginLeft: '10px' }}>
                    <Button
                      icon={EIconTypes.delete}
                      variant={EButtonVariants.outlined}
                      sizePX={EButtonSizesPX.px32}
                      onClick={() => clearAttachment()}
                    />
                  </Grid>
                </Grid>

                {errors?.licenceData && (
                  <Grid item lg={12} xs={12} style={{ marginTop: '-40px' }}>
                    <FormErrorLabel
                      label={errors?.licenceData?.message?.toString() as string}
                    />
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12} container>
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    fullWidth
                    label="Activate Licence"
                    variant={EButtonVariants.contained}
                    isLoading={isSubmitLoading}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>

          {samePageAlert && (
            <Box mt={2} sx={{ marginBottom: '10px', marginTop: '20px', width: '100%' }}>
              <Alert text={samePageAlert.text} variant={samePageAlert.variant} />
            </Box>
          )}

          <Box sx={{ marginTop: '10px' }}>
            <StyledTypography variant="subtitle1">Licence Key not working?</StyledTypography>
            <StyledTypography variant="body2">
              Please contact with the solution provider – <br />
              contact@arquebus.com
            </StyledTypography>
          </Box>
        </Grid>
      </Grid>
    </StartLicenceTemplate>
  );
};

export default StartLicencePage;

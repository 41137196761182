import { isResponseError, TResponse } from 'models/apiClient';
import {
  IAuthenticationSetupItem,
  IQlobalPropertyDetail,
  ItrustedDeviceOptions,
} from 'models/settings';
import { apiClient } from 'utils/apiClient';
import { IFormValues } from 'pages/SystemSettingsPage/AuthenticationSetupPage';
import { ApiEndpoints } from 'models/apiEndpoints';

const updateDateTimeSetup = async (dateFormat: string) =>
  apiClient.put<TResponse<IFormValues>>(
    `${ApiEndpoints.UPDATE_DATE_TIME_SETUP()}?value=${dateFormat}`,
  );

const getDateTimeSetupDetail = async (): Promise<IQlobalPropertyDetail | null> => {
  const { data } = await apiClient.get<TResponse<IQlobalPropertyDetail>>(
    ApiEndpoints.GET_DATE_TIME_SETUP(),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const updateAuthenticationSetup = async (data: any) => {
  if (data.twoFactorAuthentication) {
    await apiClient.put<TResponse<IFormValues>>(
      `${ApiEndpoints.UPDATE_TWO_FA_ENABLED()}?value=${data.twoFactorAuthentication}`,
    );
  }
  if (data.timePeriods) {
    const timePeriods: ItrustedDeviceOptions[] = Object.values(data.timePeriods);
    await apiClient.put<TResponse<IFormValues>>(
      `${ApiEndpoints.UPDATE_TRUSTED_DEVICE_OPTIONS()}`,
      timePeriods,
    );
  }
  if (data.numberOfInvalidLoginAttempts) {
    await apiClient.put<TResponse<IFormValues>>(
      `${ApiEndpoints.UPDATE_ATTEMPTS_TO_BLOCK()}?value=${data.numberOfInvalidLoginAttempts}`,
    );
  }
  if (data.timePeriodOfAccountLockout) {
    await apiClient.put<TResponse<IFormValues>>(
      `${ApiEndpoints.UPDATE_MINUTES_IN_BAN()}?value=${data.timePeriodOfAccountLockout}`,
    );
  }

  return true;
};
const getAuthenticationSetupItem = async (
  endpoint: string,
): Promise<IQlobalPropertyDetail | null> => {
  const { data } = await apiClient.get<IQlobalPropertyDetail>(endpoint);

  return data;
};

const getAuthenticationSetupItemTrustedDevice = async (): Promise<
  ItrustedDeviceOptions[] | null
> => {
  const { data } = await apiClient.get<ItrustedDeviceOptions[]>(
    ApiEndpoints.GET_TRUSTED_DEVICE_OPTIONS(),
  );
  return data;
};

const getAuthenticationSetupDetail = async (
  isOnline: boolean,
): Promise<IAuthenticationSetupItem | null> => {
  const result: IAuthenticationSetupItem = {};

  const twoFAData: IQlobalPropertyDetail | null = await getAuthenticationSetupItem(
    ApiEndpoints.GET_TWO_FA_ENABLED(),
  );
  if (twoFAData) {
    result[`${twoFAData.key}`] = twoFAData.value;
  }

  const minutesInBanData: IQlobalPropertyDetail | null = await getAuthenticationSetupItem(
    ApiEndpoints.GET_MINUTES_IN_BAN(),
  );
  if (minutesInBanData) {
    result[`${minutesInBanData.key}`] = minutesInBanData.value;
  }

  const attemptsToBlockData: IQlobalPropertyDetail | null = await getAuthenticationSetupItem(
    ApiEndpoints.GET_ATTEMPTS_TO_BLOCK(),
  );
  if (attemptsToBlockData) {
    result[`${attemptsToBlockData.key}`] = attemptsToBlockData.value;
  }

  if (isOnline) {
    const data: ItrustedDeviceOptions[] | null =
      await getAuthenticationSetupItemTrustedDevice();
    if (data && data.length) {
      const timePeriods: IAuthenticationSetupItem = [];
      // eslint-disable-next-line array-callback-return
      data.map((option) => {
        timePeriods[`${option.daysToExpire}`] = option.enabled;
      });
      result.timePeriods = timePeriods;
    }
  }

  if (isResponseError(result)) {
    return null;
  }

  return result;
};

const getTrustedDeviceOptions = async (): Promise<ItrustedDeviceOptions[] | null> => {
  const { data } = await apiClient.get<TResponse<ItrustedDeviceOptions[]>>(
    ApiEndpoints.GET_TRUSTED_DEVICE_OPTIONS_LIST(),
  );
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const getEnabledTrustedDeviceOptions = async (): Promise<ItrustedDeviceOptions[] | null> => {
  const { data } = await apiClient.get<TResponse<ItrustedDeviceOptions[]>>(
    ApiEndpoints.GET_ENABLED_TRUSTED_DEVICE_OPTIONS_LIST(),
  );
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const getTimeZoneInfo = async (): Promise<string | null> => {
  const { data } = await apiClient.get<TResponse<string>>(ApiEndpoints.GET_TIME_ZONE());
  if (isResponseError(data)) {
    return null;
  }
  return data;
};

const updateNotificationsSetup = async (value: string) =>
  apiClient.put<TResponse<IFormValues>>(
    `${ApiEndpoints.UPDATE_NOTIFICATION_SETUP()}?notificationCron=${value}`,
  );

const updateETraceSetup = async (value: boolean) =>
  apiClient.put<TResponse<IQlobalPropertyDetail>>(
    `${ApiEndpoints.UPDATE_ETRACE_SETUP()}?value=${value}`,
  );

const updateIArmsSetup = async (value: boolean) =>
  apiClient.put<TResponse<IQlobalPropertyDetail>>(
    `${ApiEndpoints.UPDATE_IARMS_SETUP()}?value=${value}`,
  );

const getNotificationsSetup = async (): Promise<IQlobalPropertyDetail | null> => {
  const { data } = await apiClient.get<TResponse<IQlobalPropertyDetail>>(
    ApiEndpoints.GET_NOTIFICATION_SETUP(),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getETraceSetup = async (): Promise<IQlobalPropertyDetail | null> => {
  const { data } = await apiClient.get<TResponse<IQlobalPropertyDetail>>(
    ApiEndpoints.GET_ETRACE_SETUP(),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

const getIArmsSetup = async (): Promise<IQlobalPropertyDetail | null> => {
  const { data } = await apiClient.get<TResponse<IQlobalPropertyDetail>>(
    ApiEndpoints.GET_IARMS_SETUP(),
  );

  if (isResponseError(data)) {
    return null;
  }

  return data;
};

export {
  updateDateTimeSetup,
  getDateTimeSetupDetail,
  updateAuthenticationSetup,
  getAuthenticationSetupDetail,
  getEnabledTrustedDeviceOptions,
  getTrustedDeviceOptions,
  updateNotificationsSetup,
  getNotificationsSetup,
  getTimeZoneInfo,
  getIArmsSetup,
  updateIArmsSetup,
  getETraceSetup,
  updateETraceSetup,
};

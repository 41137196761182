import React, { useEffect, useState } from 'react';
import Breadcrumbs from 'components/atoms/Breadcrumbs';
import { ERouteLinks } from 'models/route';
import { Box } from '@mui/system';
import { StyledPageTitle } from 'theme/styles';
import { FRTForm } from 'pages/FRT/FRTForm';
import { useNavigate } from 'react-router-dom';
import { IFRTFormValues, initialFRTFormValues } from 'models/form';
import { ActionTypes } from 'state/actions/alert';
import { EAlertVariants } from 'components/atoms/Alert';
import { useAlert } from 'models/alertContext';
import { useFRT } from 'pages/FRT/hooks';
import { useTranslations } from 'hooks/useTranslations';

const FRTAddPage = () => {
  const navigate = useNavigate();
  const { setAlert, clearAlert } = useAlert();
  const { handleAddFRT } = useFRT();
  const [initialValues, setInitialValues] = useState<IFRTFormValues>();
  const { t } = useTranslations();

  useEffect(
    () => {
      const formInitialValues = initialValues
        ? { ...initialValues }
        : { ...initialFRTFormValues };
      if (formInitialValues.productCodes.length === 0) {
        formInitialValues.productCodes.push({ productCode: '' });
      }
      setInitialValues(formInitialValues);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  useEffect(
    () => () => {
      clearAlert(ActionTypes.CLEAR_SAME_PAGE_ALERT);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleSubmit = async (data: IFRTFormValues) => {
    try {
      await handleAddFRT(data);
      navigate(`${ERouteLinks.Firearms}?expand=frt&isLocal=1`);
    } catch (e: any) {
      setAlert(ActionTypes.SET_SAME_PAGE_ALERT, {
        text: t('general.somethingWentWrong.message'),
        variant: EAlertVariants.error,
      });
    }
  };
  return (
    <>
      <Breadcrumbs
        items={[
          { label: t('module.firearm.name'), route: ERouteLinks.Firearms },
          {
            label: t('firearms.firearmsReferenceTable.accordion.name'),
            route: `${ERouteLinks.Firearms}?expand=frt`,
          },
          t('addFirearm.addFirearm.header'),
        ]}
      />
      <Box>
        <StyledPageTitle variant="h4">{t('addFirearm.addFirearm.header')}</StyledPageTitle>
      </Box>
      <FRTForm
        frtCheckIsUsed={false}
        onSubmit={handleSubmit}
        onCancel={() => navigate(`${ERouteLinks.Firearms}?expand=frt`)}
        initialFormValues={initialValues}
      />
    </>
  );
};

export { FRTAddPage };

import React, { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { Drawer } from 'components/organisms/Drawer';
import { Footer } from 'components/atoms/Footer';
import { FC_SYSTEM_AUTH_ACCESS_TOKEN } from 'requests/auth';
import { useLocation } from 'react-router-dom';
import { config } from 'pages/config';
import { ERouteLinks, IPageBuilderConfig } from 'models/route';
import { HamburgerMenu } from 'components/molecules/HamburgerMenu';
import { useThemeBreakpoint } from 'hooks/useThemeBreakpoint';
import { useAuth } from 'models/authContext';
import { useLang } from 'models/langContext';
import { LanguageSelect } from 'components/molecules/LanguageSelect';
import { FC_SYSTEM_APP_LANG } from 'requests/language';
import { isPathEqualToRouteName } from 'utils/route';
import { Loader } from 'components/atoms/Loader';
import {
  PageWrapper,
  StyledLanguageSelectOuterWrapper,
  StyledLanguageSelectWrapper,
} from './styles';

const Layout = ({ children }: PropsWithChildren<{}>): ReactElement => {
  const { isDesktop } = useThemeBreakpoint();

  const [open, setOpen] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);

  const location = useLocation();

  const { getUser, user } = useAuth();

  const { getTranslationsData, changeLanguage, languages, selectedLanguage } = useLang();

  useEffect(() => {
    if (languages && languages.length > 0) {
      const browserLang = languages.find((lang) => navigator.language.includes(lang.code));
      const defaultLang = languages.find((lang) => lang.defaultLanguage);

      const localStorageLang = localStorage.getItem(FC_SYSTEM_APP_LANG)
        ? languages.find((lang) =>
            localStorage.getItem(FC_SYSTEM_APP_LANG)?.includes(lang.code),
          )
        : null;

      const lang = localStorageLang || defaultLang || browserLang || null;

      changeLanguage(lang);

      localStorage.setItem(FC_SYSTEM_APP_LANG, lang?.code || '');
    }
  }, [languages]);

  useEffect(() => {
    if (selectedLanguage) {
      getTranslationsData(selectedLanguage);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    setIsAuthenticated(!!localStorage.getItem(FC_SYSTEM_AUTH_ACCESS_TOKEN));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem(FC_SYSTEM_AUTH_ACCESS_TOKEN)]);

  useEffect(() => {
    const fetchUser = async () => {
      if (user === null) {
        await getUser();
      }
    };
    fetchUser();
  }, [user]);

  const handleDrawerToggle = (value: boolean) => {
    setOpen(value);
  };

  document.addEventListener(
    'itemInserted',
    () => {
      if (localStorage.getItem(FC_SYSTEM_AUTH_ACCESS_TOKEN)) {
        setIsAuthenticated(true);
      }
    },
    false,
  );

  document.addEventListener(
    'itemRemoved',
    () => {
      if (localStorage.getItem(FC_SYSTEM_AUTH_ACCESS_TOKEN) && user) {
        setIsAuthenticated(false);
      }
    },
    false,
  );

  const isRouteNotProtected =
    config.find((route: IPageBuilderConfig) => route.route === location.pathname)
      ?.isProtected === false;

  const isRouteWithoutLayoutMenu = isPathEqualToRouteName(
    location.pathname,
    ERouteLinks.ViewRegisteredFirearmAttachment,
  );

  return (
    <>
      {isAuthenticated && !isRouteNotProtected && !isDesktop && !isRouteWithoutLayoutMenu && (
        <HamburgerMenu />
      )}
      <Box sx={{ display: 'flex', width: '100vw', maxWidth: '100%' }}>
        {isAuthenticated === true && !isRouteNotProtected && (
          <>
            {isDesktop && !isRouteWithoutLayoutMenu && (
              <Drawer open={open} setOpen={handleDrawerToggle} />
            )}
            <Box component="main" sx={{ flexGrow: 1 }}>
              <PageWrapper>
                <StyledLanguageSelectOuterWrapper>
                  <StyledLanguageSelectWrapper>
                    <LanguageSelect isVisible={isDesktop} />
                  </StyledLanguageSelectWrapper>
                </StyledLanguageSelectOuterWrapper>
                {selectedLanguage ? (
                  children
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: 'calc(100vh - 64px)',
                    }}
                  >
                    <Loader isLoading={true} />
                  </Box>
                )}
                {/* {children} If blank page appear this was state before */}
              </PageWrapper>
              <Footer />
            </Box>
          </>
        )}
        {((isAuthenticated === true && isRouteNotProtected) || isAuthenticated === false) && (
          <Box component="main" sx={{ flexGrow: 1 }}>
            {children}
          </Box>
        )}
      </Box>
      {/* if white screen will appear anywhere back this changes */}
    </>
  );
};

export { Layout };

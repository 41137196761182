import React, { useEffect, useState } from 'react';
import { StyledFormElementWrapper } from 'theme/styles';
import FormLabel from 'components/atoms/FormLabel';
import { IFormElementOptionProp, IFormElementProps } from 'models/form';
import { Controller, useController } from 'react-hook-form';
import { StyledTextField } from 'components/molecules/FormDatePicker/styles';
import FormErrorLabel from 'components/atoms/FormErrorLabel';
import { EDictionaryTypes } from 'models/dictionary';
import { getDictionaryNamesLike } from 'requests/dictionary';
import { useTranslations } from 'hooks/useTranslations';
import { EIconTypes } from 'constants/Icons';
import Icon from 'components/atoms/Icon/Icon';
import { StyledAutocomplete, StyledFormControl, StyledInnerWrapper } from './styles';

interface IFormAutocomplete extends IFormElementProps {
  options?: IFormElementOptionProp[];
  className?: string;
  loadOptions?: (arg?: any) => Promise<IFormElementOptionProp[]>;
  freeSolo?: boolean;
  disabled?: boolean;
  required?: boolean;
  optionsLimiter?: EDictionaryTypes;
}

const AUTOCOMPLETE_CHARS_LIMIT = 2;
const REQUEST_TIME_INTERVAL = 500;
const ERROR_TYPE = 'custom';

export const FormAutocomplete = ({
  name,
  label,
  control,
  errors,
  setError,
  clearErrors,
  className,
  options,
  loadOptions,
  freeSolo,
  disabled,
  required,
  optionsLimiter,
}: IFormAutocomplete) => {
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false);
  const { field: cField } = useController({ name, control });
  const [changed, setChanged] = useState(false);
  const [lastRequestTime, setLastRequestTime] = useState(0);
  const defaultValue = options?.find((option) => option.value === cField.value);
  const { t } = useTranslations();

  const [asyncLoadedOptions, setAsyncLoadedOptions] = useState<IFormElementOptionProp[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  useEffect(() => {
    if (cField.value === '' && inputValue && changed) {
      setInputValue('');
      setChanged(false);
    }

    if (cField.value && defaultValue && !changed) {
      setInputValue(defaultValue.label);
      setChanged(true);
    }
    if (cField.value && optionsLimiter && !changed) {
      setInputValue(cField.value);
      setChanged(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cField]);

  const changeAsyncLoadedOptionsState = async (isFetchMore?: boolean) => {
    if (loadOptions) {
      setLoading(true);
      const values = await loadOptions(inputValue?.trim());
      setAsyncLoadedOptions((prevState) =>
        [...(isFetchMore ? prevState : []), ...values].map(
          (option: IFormElementOptionProp) => ({
            label: option.label,
            value: option.value,
          }),
        ),
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loadOptions && inputValue?.trim()?.length >= AUTOCOMPLETE_CHARS_LIMIT) {
      (async () => {
        await changeAsyncLoadedOptionsState();
      })();
    }

    if (optionsLimiter && inputValue?.trim()?.length >= AUTOCOMPLETE_CHARS_LIMIT) {
      const currentTime = new Date().getTime();
      const timeSinceLastRequest = currentTime - lastRequestTime;

      if (timeSinceLastRequest >= REQUEST_TIME_INTERVAL) {
        setLastRequestTime(currentTime);
        (async function init() {
          const response = await getDictionaryNamesLike(optionsLimiter, {
            searchValue: inputValue,
          });
          if (response) {
            const values = Object.values(response) as unknown as string[];
            const formattedOptions = values.map((value: string) => ({
              label: value,
              value,
            }));
            setAsyncLoadedOptions(formattedOptions);
          }
        })();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const handleOpen = () => {
    if (inputValue.length >= AUTOCOMPLETE_CHARS_LIMIT) {
      setOpen(true);
    }
  };

  const isInOptions = (value: string) => {
    const escapeRegex = (str: string) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(`^${escapeRegex(value.toLowerCase())}`, 'i');
    if (options && options?.find((option) => regex.test(option.label.toLowerCase()))) {
      return true;
    }
    if (
      asyncLoadedOptions &&
      asyncLoadedOptions?.find((option) => regex.test(option.label.toLowerCase()))
    ) {
      return true;
    }
    return false;
  };

  const isOneOption = (value: string) => {
    if (
      options &&
      options?.find((option) => option.label.toLowerCase() === value.toLowerCase())
    ) {
      return true;
    }
    if (
      asyncLoadedOptions &&
      asyncLoadedOptions?.find((option) => option.label.toLowerCase() === value.toLowerCase())
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (setError && clearErrors && (options?.length || asyncLoadedOptions.length)) {
      if (inputValue.length >= AUTOCOMPLETE_CHARS_LIMIT) {
        if (isInOptions(inputValue)) {
          if (open) {
            clearErrors(name);
          }
        } else {
          setError(name, {
            type: ERROR_TYPE,
            message: t('formAutocomplete.validation.text'),
          });
          cField.onChange('');
          setChanged(false);
        }
      } else if (inputValue.length < AUTOCOMPLETE_CHARS_LIMIT && inputValue.length > 0) {
        clearErrors(name);
      }

      if (inputValue.length === 0 || !isOneOption(inputValue)) {
        cField.onChange('');
        setChanged(false);
      }
    }
  }, [options, asyncLoadedOptions, inputValue]);

  return (
    <StyledFormElementWrapper className={className}>
      <FormLabel name={name} label={label} disabled={disabled} required={required} />
      <StyledInnerWrapper>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <StyledFormControl className={className} error={!!errors[name]?.message}>
              <StyledAutocomplete
                open={open}
                onOpen={handleOpen}
                onClose={() => setOpen(false)}
                inputValue={inputValue}
                forcePopupIcon={false}
                popupIcon={null}
                clearIcon={<Icon type={EIconTypes.clear} />}
                onInputChange={(event: React.SyntheticEvent, newInputValue: string) => {
                  if (event) {
                    setInputValue(newInputValue);

                    if (newInputValue.length >= AUTOCOMPLETE_CHARS_LIMIT) {
                      setOpen(true);
                    } else {
                      setOpen(false);
                    }
                  }
                }}
                getOptionLabel={(option: any) => option.label}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.value === value.value
                }
                onChange={(e, data: any) => {
                  field.onChange(data?.value || '');
                  setChanged(true);
                }}
                loading={loading}
                freeSolo={freeSolo}
                options={options || asyncLoadedOptions}
                renderInput={(params) => (
                  <StyledTextField
                    ref={field.ref}
                    {...params}
                    placeholder={t('formAutoComplete.all.placeholder')}
                  />
                )}
                disabled={disabled}
                id={name}
              />
            </StyledFormControl>
          )}
        />
      </StyledInnerWrapper>
      <FormErrorLabel label={errors[name]?.message} />
    </StyledFormElementWrapper>
  );
};
